const Utils = (function() {
    const isTouchScreen = (('createTouch' in document) || ('ontouchend' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    const clickEvent = isTouchScreen ? 'touchstart' : 'click';

    const appHeight = (function appHeight() {
        const header = document.getElementById('header');
        const footer = document.getElementById('footer');
        const doc = document.documentElement;

        doc.style.setProperty('--app-height', `${window.innerHeight}px`);

        if (null !== footer) {
            doc.style.setProperty('--footer-height', `${footer.offsetHeight}px`);
        }

        if (null !== header) {
            doc.style.setProperty('--header-height', `${header.offsetHeight}px`);
        }

        return appHeight;
    })();

    window.addEventListener('resize', appHeight);
    window.addEventListener('load', appHeight);

    const isVisible = function(elem) {
        return window.getComputedStyle(elem).display !== 'none' && elem.clientHeight !== 0 && elem.getClientRects().length !== 0;
    };

    const debounce = function(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    return {
        isTouchScreen: isTouchScreen,
        clickEvent: clickEvent,
        isVisible: isVisible,
        debounce: debounce
    }
})();


var Forbes = (function($, undefined)
{
    "use strict";

    var $window = $(window);

    // Send the info to Google Analytics
    var gtmClickTrack = function(url) {
        ga('send', 'event', 'outbound', 'click', url, {
            'transport': 'beacon'
        });
    }


    var normalizeTeasers = function($context) {
        $context = undefined === $context ? $(document) : $context;

        var $teasers = $context.find('.random-list .layout_latest, .related-list .layout_latest, .tut-list .layout_latest, .advertorial-list .layout_latest, .womenssummit .layout_latest, .normalize-teasers .layout_latest');
        $teasers.data('normalized', false);
        $teasers.each(function()
        {
            // get the teaser element
            var $teaser = $(this);

            // check if already normalized
            if ($teaser.data('normalized') || $window.width() < 600)
            {
                return true;
            }

            // get siblings
            var $siblings = $teaser.siblings('.layout_latest').addBack().filter(':visible');

            // get the max offset for image
            var maxOffsetHeadlines = 0;
            var maxULHeight = 0;
            $siblings.find('h1,h2,h3').css('padding-top', 0).each(function()
            {
                var offset = $(this).position().top + $(this).outerHeight();
                if (offset > maxOffsetHeadlines)
                {
                    maxOffsetHeadlines = offset;
                }
            });

            $siblings.find('ul.categories').css('padding-top', 0).each(function()
            {
                var height = $(this).outerHeight();
                if (height > maxULHeight)
                {
                    maxULHeight = height;
                }
            });

            // process each sibling
            $siblings.each(function()
            {
                // get the sibling
                var $sibling = $(this);

                // get the headline
                var $hl = $sibling.find('h1,h2,h3');

                // get the list
                var $ul = $sibling.find('ul.categories');
                var $ulHeight = $ul.height();

                if($ulHeight < maxULHeight) {
                    $ul.css('padding-top', maxULHeight - $ulHeight + 'px');
                }

                if($hl.position()){
                    // set padding
                    $sibling.find('h1,h2,h3').css('padding-top', (maxOffsetHeadlines - $hl.position().top - $hl.outerHeight()) + 'px');

                    // set to normalized
                    $sibling.data('normalized', true);
                }

            });
        });
    };

    var containerMinHeight = function()
    {
        $('#container').css('min-height', ($window.height() - $('#header').outerHeight() - $('#footer').outerHeight()) + 'px');
    };

    $(document).ready(function()
    {
        /**
         * Get some elements
         */
        var $header = $('#header');
        var $body   = $('body');

        /**
         * Close overlay
         */
        $('#about-overlay .closebutton').click(function(e)
        {
            e.preventDefault();
            $('body').removeClass('about-open');
            $('#about-overlay').fadeOut(200);

            $('#burger').removeClass('run-open');

            return false;
        });

        /**
         * Close overlay
         */
        $('#women-overlay .closebutton').click(function(e)
        {
            e.preventDefault();
            $('body').removeClass('about-open');
            $('#women-overlay').fadeOut(200);

            $('#burger').removeClass('run-open');

            return false;
        });

        /**
        * Google Analytics Klick Tracking
        */
        $('.ga-click-tracking').on('click', function() {
          gtmClickTrack($(this).attr('href'));
        });

        /**
        * active category
        */
        $('#startup-category-list li').first().addClass('active');
        $('#founders-category-list li').first().addClass('active');
        $('#jobs-category-list li').first().addClass('active');

        /**
         * Normalize teasers
         */
        normalizeTeasers();


        /**
         * #container min height
         */
        containerMinHeight();

        /**
         * klick for more
         */
         $('.show-more').click(function() {
             $('.hide').show();
             $('.show-more').hide();

              return false;
            });

        /**
         * Parallax effect
         */
        /* removed (see https://gitlab.com/inspiredminds/forbes/-/issues/434)
        var $figures = $('.parallax-list .layout_latest figure, .layout_full .figure-container figure');
        var parallax = function()
        {
            var windowHeight = $window.height();
            var scrollPosTop = $window.scrollTop();
            var scrollPosBottom = scrollPosTop + windowHeight;

            $figures.each(function()
            {
                var $figure = $(this);
                var figureTop = $figure.offset().top;
                var figureHeight = $figure.outerHeight();
                var figureBottom = figureTop + figureHeight;

                if (figureBottom < scrollPosTop || figureTop > scrollPosBottom)
                {
                    // skip
                    return true;
                }

                var $img = $figure.find('img');
                var factor = (scrollPosBottom - figureTop) / (windowHeight + figureHeight);
                var range = $img.height() - figureHeight;
                var top = range * factor;
                $img.css('top',(-range+top)+'px');
            });

            if (undefined !== window.requestAnimationFrame)
            {
                window.requestAnimationFrame(parallax);
            }
        };
        $window.on('load', parallax);
        */


        /**
         * More links
         */
        $('.more-link a[href^="#"]').each(function()
        {
            var $link = $(this);
            var $list = $($link.attr('href'));
            var $elements = $list.find('.layout_latest');
            var initialThresh = 3;
            var continueThresh = 6;

            // hide initial news
            $elements.filter(function(i){ return i >= initialThresh; }).hide();

            // click action
            $link.click(function(e)
            {
                e.preventDefault();
                $elements.filter(function(i){ return i >= initialThresh && i < initialThresh + continueThresh; }).fadeIn();
                if (initialThresh + continueThresh >= $elements.length)
                {
                    $link.parent().hide();
                }
                initialThresh += continueThresh;
                return false;
            });
        });


        /**
         * Smooth scrolling to anchor
         */
        $('a[href*=\\#]').on('click', function(event)
        {
            if ($(this.hash).length > 0 && $(this).closest('.more-link').length == 0 && $(this).attr('class') != 'ui-tabs-anchor')
            {
                event.preventDefault();
                $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
            }
        });


        /**
         * Auto grow text areas
         */
        const autoresize = function(textarea) {
            if (Utils.isVisible(textarea)) {
                textarea.style.minHeight = '0px';
                textarea.style.minHeight = textarea.scrollHeight + 'px';
            }
        };

        const registerAutoresize = function(form) {
            form.querySelectorAll('textarea').forEach(function(textarea) {
                autoresize(textarea);
                textarea.addEventListener('input', function() {
                    autoresize(textarea);
                });
            });  
        }

        document.querySelectorAll('form').forEach(function(form) {
            registerAutoresize(form);
            form.addEventListener('ajax_change', function(){
                registerAutoresize(form);
            });
        });


        /**
         * AJAX loading
         */
        $('.ajax-loading').each(function()
        {
            var $this = $(this);
            var $links = $this.find('a');
            var $target = $('#ajax-target');

            if ($target.length !== 0 && undefined !== $target.data('ajax-container'))
            {
                $links.click(function(e)
                {
                    if(!$(this).hasClass('alumni')){
                        $('a').removeClass('active');

                        $(this).addClass('active');

                        $target.addClass('ajax-loading');
                        $target.load($(this).attr('href') + ' ' + $target.data('ajax-container'), function()
                        {						
                            $target.removeClass('ajax-loading');
                            Forbes.normalizeTeasers($target);
                        });

                        e.preventDefault();
                        return false;
                    }
                });
            }
        });


        /**
         * Voting
         */
        var $votebuttons = $('.layout_full a.vote-button, .layout_voting a.vote-button');
        $votebuttons.each(function()
        {
            var $a = $(this);

            $a.click(function(e)
            {
                e.preventDefault();

                if ($a.hasClass('disabled'))
                {
                    return false;
                }

                $a.addClass('disabled');

                $.get($a.attr('href'), function(response)
                {
                    if (!response.voted)
                    {
                        $a.removeClass('disabled');
                    }

                    if (response.votesleft == 0)
                    {
                        $votebuttons.addClass('disabled');
                    }

                    $('#header .remaining-votes .num').text(response.votesleft);

                    $('.layout_full p.vote-count').each(function()
                    {
                        $(this).text(response.newsvotes + ' ' + (response.newsvotes == 1 ? 'Vote' : 'Votes'));
                    });

                }).failure(function()
                {
                    $a.removeClass('disabled');
                });

                return false;
            });
        });


        /**
         * Founders Slide to default category and slide to category on click
         */
        setTimeout(function()
        {
            var $categoryslider = $('#jobs-category-slider');
            var $categorynav    = $('#jobs-category-list');

            if ($categoryslider.length > 0 && $categorynav.length > 0)
            {
                var activeIndex = $categorynav.find('li.active').index();
                var swiper = $categoryslider.find('.swiper-container').get(0).swiper;
                swiper.slideTo(activeIndex, 0);
                $categoryslider.find('.swiper-pagination-fraction').append(' <span class="category">'+$categorynav.find('li.active').text()+'</span>');

                swiper.on('slideChange', function()
                {
                    $categorynav.find('li,a').removeClass('active');
                    var text = $categorynav.find('li').eq(swiper.activeIndex).addClass('active').text();

                    $categoryslider.find('.swiper-pagination-fraction .category').text(text);

                    $('#jobs-category-slider .swiper-wrapper').css('height', $('#jobs-category-slider .swiper-slide-active .articles').height());
                });

                $categorynav.find('li').each(function(index, element)
                {
                    var $li = $(this);
                    $li.find('a').click(function()
                    {
                        swiper.slideTo($li.index());
                        return false;
                    });
                });
            }
        }, 0);



        /**
         * Founders Slide to default category and slide to category on click
         */
        setTimeout(function()
        {
            var $categoryslider = $('#startup-category-slider');
            var $categorynav    = $('#startup-category-list');

            if ($categoryslider.length > 0 && $categorynav.length > 0)
            {
                var activeIndex = $categorynav.find('li.active').index();
                var swiper = $categoryslider.find('.swiper-container').get(0).swiper;
                swiper.slideTo(activeIndex, 0);
                $categoryslider.find('.swiper-pagination-fraction').append(' <span class="category">'+$categorynav.find('li.active').text()+'</span>');

                swiper.on('slideChange', function()
                {
                    $categorynav.find('li,a').removeClass('active');
                    var text = $categorynav.find('li').eq(swiper.activeIndex).addClass('active').text();

                    $categoryslider.find('.swiper-pagination-fraction .category').text(text);
                    setTimeout(function(){
                        console.log($('#startup-category-slider .first-slide .articles').height());
                        $('#startup-category-slider .swiper-wrapper').css('height', $('#startup-category-slider .swiper-slide-active .articles').height());
                    }, 10);

                    if($('#startup-category-slider .swiper-slide-active .articles').height() < 410){
                        $('.swiper-button-prev').css('cssText','top:33%!important');
                        $('.swiper-button-next').css('cssText','top:33%!important');
                    }else{
                        $('.swiper-button-prev').css('cssText','top:340px!important');
                        $('.swiper-button-next').css('cssText','top:340px!important');
                    }
                });

                $categorynav.find('li').each(function(index, element)
                {
                    var $li = $(this);
                    $li.find('a').click(function()
                    {
                        swiper.slideTo($li.index());
                        return false;
                    });
                });
            }
        }, 0);

        /**
         * Founders Slide to default category and slide to category on click
         */
        setTimeout(function()
        {
            var $categoryslider = $('#founders-slider-uni');
            var $categorynav    = $('#founders-category-list');

            if ($categoryslider.length > 0 && $categorynav.length > 0)
            {
                var activeIndex = $categorynav.find('li.active').index();
                var swiper = $categoryslider.find('.swiper-container').get(0).swiper;
                swiper.slideTo(activeIndex, 0);
                $categoryslider.find('.swiper-pagination-fraction').append(' <span class="category">'+$categorynav.find('li.active').text()+'</span>');

                swiper.on('slideChange', function()
                {
                    $categorynav.find('li,a').removeClass('active');
                    var text = $categorynav.find('li').eq(swiper.activeIndex).addClass('active').text();

                    $categoryslider.find('.swiper-pagination-fraction .category').text(text);

                    

                    setTimeout(function(){
                        $('#founders-slider-uni .swiper-wrapper').css('height', $('#founders-slider-uni .swiper-slide-active .articles').height());
                    }, 10);

                    if($('#founders-slider-uni .swiper-slide-active .articles').height() < 410){
                        $('.swiper-button-prev').css('cssText','top:33%!important');
                        $('.swiper-button-next').css('cssText','top:33%!important');
                    }else{
                        $('.swiper-button-prev').css('cssText','top:340px!important');
                        $('.swiper-button-next').css('cssText','top:340px!important');
                    }

                });

                $categorynav.find('li').each(function(index, element)
                {
                    var $li = $(this);
                    $li.find('a').click(function()
                    {
                        swiper.slideTo($li.index());
                        return false;
                    });
                });
            }
        }, 0);

                /**
         * Founders Slide to default category and slide to category on click
         */
        setTimeout(function()
        {
            var $categoryslider = $('#jobs-category-slider');
            var $categorynav    = $('#founders-category-list');

            if ($categoryslider.length > 0 && $categorynav.length > 0)
            {
                var activeIndex = $categorynav.find('li.active').index();
                var swiper = $categoryslider.find('.swiper-container').get(0).swiper;
                swiper.slideTo(activeIndex, 0);
                $categoryslider.find('.swiper-pagination-fraction').append(' <span class="category">'+$categorynav.find('li.active').text()+'</span>');

                swiper.on('slideChange', function()
                {
                    $categorynav.find('li,a').removeClass('active');
                    var text = $categorynav.find('li').eq(swiper.activeIndex).addClass('active').text();

                    $categoryslider.find('.swiper-pagination-fraction .category').text(text);

                    

                    setTimeout(function(){
                        $('#jobs-category-slider .swiper-wrapper').css('height', $('#jobs-category-slider .swiper-slide-active .articles').height());
                    }, 10);

                    if($('#jobs-category-slider .swiper-slide-active .articles').height() < 410){
                        $('.swiper-button-prev').css('cssText','top:33%!important');
                        $('.swiper-button-next').css('cssText','top:33%!important');
                    }else{
                        $('.swiper-button-prev').css('cssText','top:340px!important');
                        $('.swiper-button-next').css('cssText','top:340px!important');
                    }

                });

                $categorynav.find('li').each(function(index, element)
                {
                    var $li = $(this);
                    $li.find('a').click(function()
                    {
                        swiper.slideTo($li.index());
                        return false;
                    });
                });
            }
        }, 0);

        /**
         * Mouse over for ce_swiperStart
         */
        $('.ce_swiperStart').mousemove(function(e)
        {
            var $this = $(this);
            var x = e.pageX - $this.offset().left;
            var t = $this.width() * 0.5;

            if (x < t)
            {
                $this.removeClass('show-right-button').addClass('show-left-button');
            }
            else
            {
                $this.removeClass('show-left-button').addClass('show-right-button');
            }

        }).mouseout(function()
        {
            $(this).removeClass('show-left-button show-right-button');
        });
    });

    $window.on('load', function()
    {
        /**
        set height of slider founders
        */
        //$('#startup-category-slider .swiper-wrapper').css('height', $('#startup-category-slider .swiper-slide-active .articles').height());
        $('#startup-category-slider .swiper-wrapper').css('height', $('#startup-category-slider .first-slide .articles').height());
        $('#founders-slider-uni .swiper-wrapper').css('height', $('#founders-slider-uni .first-slide .articles').height());
        $('#jobs-category-slider .swiper-wrapper').css('height', $('#jobs-category-slider .first-slide .articles').height());

        /**
         * Normalize teasers
         */
        normalizeTeasers();
    });

    $window.resize(function()
    {
        /**
         * Normalize teasers
         */
        normalizeTeasers();

        /**
         * #container min height
         */
        containerMinHeight();
    });

    /**
     * Forbes Founders overlay
     */
    var forbesFoundersLoginOverlay = function()
    {
        var $sponsors = $('#sponsoren');
        $('.site--forbes-founders .founders-login-overlay').each(function()
        {
            $(this).css('min-height', $sponsors.offset().top + $sponsors.outerHeight() - $('#header').height());
        });

        if($(window).height() < 819 && $(window).width() > 1000){
            $('#sponsoren').css('height', 140);
        }else if($(window).width() > 1000){
            $('#sponsoren').css('height', 170);
        }

        $('.thirtyunderthirty #sponsoren').css('height', 140);
    }

    $(document).on('ready', forbesFoundersLoginOverlay);
    $(window).on('load', forbesFoundersLoginOverlay);
    $(window).on('resize',forbesFoundersLoginOverlay);

    var forbesUrbanStragetiesGif = function(){
        /**
        * Urban Strategies Gif
        */
        var $USheigt = $('.urban-strategies .ce_rsce_header').outerHeight();
        var $USwidth = $('.urban-strategies .ce_rsce_header').outerWidth();

        if($USheigt > 570)
        {
            $('.urban-strategies .ce_rsce_header img').css('margin-top', '-7%');
            $('.urban-strategies #header').css('position', 'relative');
            $('.urban-strategies #header').css('z-index', '1');
        }

        if($USheigt < 570)
        {
            $('.urban-strategies .ce_rsce_header img').css('margin-top', '-4%');
            $('.urban-strategies #header').css('position', 'relative');
            $('.urban-strategies #header').css('z-index', '1');
        }

        if($USwidth < 599){
            $('.urban-strategies .ce_rsce_header img').css('margin-top', '-25%');
        }
    }

    $(document).on('ready', forbesUrbanStragetiesGif);
    $(window).on('load', forbesUrbanStragetiesGif);
    $(window).on('resize',forbesUrbanStragetiesGif);

    var forbesThirtyImg = function(){
        /**
        * Urban Strategies Gif
        */
        var $USheigt = $('.underthirty-2020 .ce_rsce_header').outerHeight();
        var $USwidth = $('.underthirty-2020 .ce_rsce_header').outerWidth();

        if($USheigt > 570)
        {
            $('.underthirty-2020 .ce_rsce_header img').css('margin-top', '-7%');
            $('.underthirty-2020 #header').css('position', 'relative');
            $('.underthirty-2020 #header').css('z-index', '1');
        }

        if($USheigt < 570)
        {
            $('.underthirty-2020 .ce_rsce_header img').css('margin-top', '-4%');
            $('.underthirty-2020 #header').css('position', 'relative');
            $('.underthirty-2020 #header').css('z-index', '1');
        }

        if($USwidth < 599){
            $('.underthirty-2020 .ce_rsce_header img').css('margin-top', '-25%');
        }
    }

    $(document).on('ready', forbesThirtyImg);
    $(window).on('load', forbesThirtyImg);
    $(window).on('resize',forbesThirtyImg);


    /**
     * Parallax ad
     */
    $('.ce_rsce_banner .banner__content-inner').each(function() {
        $(this).height($(this).height());
    });


    /**
     * toplink
     */
    $('#toplink').each(function() {
        var $toplink = $(this);
        $(window).on('scroll', function() {
            if ($(window).scrollTop() + $(window).height() * 0.5 >= $(document).height() * 0.5) {
                $toplink.addClass('show');
            } else {
                $toplink.removeClass('show');
            }
        });
    });


    $('.ce_rsce_addtocalendar').each(function() {
        var $this = $(this);
        $this.find('.addtocalendar__button').on('touchend', function() {
            $this.toggleClass('hover');
        });
    });

    function isTouchDevice() {
        return (('ontouchstart' in window) ||
           (navigator.maxTouchPoints > 0) ||
           (navigator.msMaxTouchPoints > 0));
      }

    const toggleMenu = (close = null) => {
        let isOpen = close == null ? menuBar.classList.contains("active") : close;
        menuBar.classList.toggle("active", !isOpen);
        hoverOverlay.classList.toggle("active", !isOpen);
    }

    let menuBar = document.querySelector('#ws-menu-bar');
    let hoverOverlay = document.querySelector('#ws-hover-overlay');
    let hoverMenuToggleBurger = document.querySelector('.ws-menubar-toggle-burger');
    let hoverOverlayCloseButton = document.querySelector('.ws-hovermenu-close-button');

    let overlayLinks = document.querySelectorAll('.ws-jumplink')
    if(menuBar != null)
    {
        overlayLinks.forEach((link) => {
            link.addEventListener('click', () => {
                toggleMenu(true);
            });
        });

        if(!isTouchDevice()){
            menuBar.addEventListener('mouseenter', () => {
                toggleMenu(false);
            });
            menuBar.addEventListener("mouseleave", () => {
                toggleMenu(true);
            })
        }
        hoverMenuToggleBurger.addEventListener('click', () => {
            toggleMenu();
        });
        hoverOverlayCloseButton.addEventListener('click', () => {
            toggleMenu();
        });
    }

    // Women's Summit 2021 Break Out Sessions
    const options = document.querySelectorAll('.ws2021bos__radio');

    options.forEach( o => o.addEventListener('change', (e) => {
        const input = document.querySelector('input[name="session"]');

        if (input) {
            input.value = e.target.value;
        }
    }));

    const bosform = document.querySelector('#ws-bos-form form');

    if (bosform) {
        bosform.addEventListener('submit', () => {
            const input = document.querySelector('input[name="session"]');

            if (input && !input.value) {
                alert('Please choose a session!');
                return false;
            }

            return true;
        });
    }

    // bos jumplink menu position
    const jumpLinkMenu = document.querySelector('#ws-menu-bar');
    const header = document.querySelector('#Header h1');

    if ( jumpLinkMenu && header ) {
        const updateJumpLinkMenu = () => {
            console.log('resize, update');
            const bRect = document.body.getBoundingClientRect();
            const jRect = jumpLinkMenu.getBoundingClientRect();
            const hRect = header.getBoundingClientRect();

            const hy = hRect.y - bRect.y;

            if( jRect.x > (bRect.width/2) ){ // mobile
                jumpLinkMenu.style.top = '';
            }else{
                jumpLinkMenu.style.top = `${hy + (hRect.height / 2) - (jRect.height / 2)}px`;
            }
        };

        window.addEventListener('resize', updateJumpLinkMenu);
        updateJumpLinkMenu();
    }

    return {
        normalizeTeasers: normalizeTeasers
    }

})(jQuery);
